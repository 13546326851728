@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-footer {
    width: 100%;
    background-color: $black;
    color: $white;
    padding: $spacing-16 $spacing-16;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: $spacing-16;

    .separator {
        filter: brightness(200%);
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .c-menu-item {
        color: $white;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }

    &__disclaimer {
        max-width: 64rem;
    }
}

@include desktop {
    .c-footer {
        flex-direction: row;
        padding: $spacing-32 $spacing-32;

        .c-menu-item {
            text-align: right;
            padding-top: $spacing-4;
            padding-bottom: $spacing-4;
            margin-right: -1.6rem;
        }

        &__disclaimer {
            text-align: left;
        }
    }
}