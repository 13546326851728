@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-welcome {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-32;
    gap: $spacing-8;
    margin-bottom: $spacing-32;

    &__photography {
        width: 100%;
        max-width: 100%;
        .image img, .image {
            max-width: 100%;
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
    }

    &__text {
        position: relative;
        z-index: 1;
        line-height: 1.6;
        display: flex;
        flex-direction: column;
        gap: $spacing-16;

        ul {
            display: flex;
            flex-direction: column;
            gap: $spacing-4;
        }

        ul, p {
            position: relative;
            z-index: 1;
        }
    }
    
    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 0;
        bottom: 0;
        opacity: .5;
        z-index: 0;
    }
}

@media (min-width: 800px) {
    .c-welcome {

        &__photography {
            flex: 1 1;
            position: relative;
            min-height: 100%;
            width: 50%;
            max-width: 50%;

            .image__wrapper {
                min-height: 100%;
            }
            img {
                max-width: 100%;
            }
        }

        &__text {
            flex: 1 1;
            width: 50%;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            gap: $spacing-2;
        }

        &__text-container {
            display: flex;
            flex-direction: row;
            gap: $spacing-16;
        }
    }
}


@include desktop {
    .c-welcome {
        margin-top: 0;
        padding: $spacing-32;
        margin-bottom: $spacing-16;
        &__text {
            font-size: $font-size-20;
        }

        &__text {
            position: relative;
            gap: $spacing-16;
        }
    }
}