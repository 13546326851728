@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-spacer-1,
.c-spacer-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: auto;
        height: 2.4rem;
    }
}

.c-spacer-1 {
    height: .8rem;
}

.c-voucherpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    width: 100%;
    overflow: hidden;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    gap: 0;

    &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__scroll-container {
        flex: 1 1;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        
        background:
            /* Shadow covers */
            linear-gradient(white 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
            
            /* Shadows */
            /* radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), */
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        
        /* Opera doesn’t support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        align-items: center;
        justify-content: center;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        padding-bottom: 1.6rem;

        img {
            max-width: 100%;
            height: auto;
        }

        div {
            text-align: center;
            width: 100%;
        }
    }

    &__walking-women {
        height: 25vh;
        max-height: 40vh;
        max-width: 40vh;
        img {
            width: auto;
            max-height: 100%;
        }
        @media (min-width: $breakpoint-desktop) {
            height: auto;
            img {
                width: auto;
                height: 100%;
            }
        }

    }

    &__footer {
        bottom: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    &__welcome,
    &__opening-hours {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    &__discount {
        color: $primary-color;
        font-size: $font-size-20;
    }
}