@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-massage-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    box-shadow: $shadow-centered;
    background-color: $white;

    &__illustration {
        width: 25.6rem;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        padding: 0 $spacing-16 $spacing-16 $spacing-16;
    }

    &__title {
        text-align: center;
        font-weight: bold;
    }

    &__prices {
        display: flex;
        gap: $spacing-8;
    }

    &__prices-text {
        display: flex;
        flex-direction: column;
    }

    &__more-button {
        align-self: flex-end;
    }
}

@include desktop {
    .c-massage-card {
        width: 30rem;

        &__description {
            flex-grow: 1;
        }
    }
}
