@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-agreement {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    section {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        p {
            max-width: 96rem;
            line-height: 1.6;
        }
    }


}

@include desktop {
    .c-agreement {
        padding: $spacing-32;
        margin-top: 0;
    }
}