@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-impressum-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    section {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        p {
            max-width: 96rem;
            line-height: 1.6;
        }
    }

    & .botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        opacity: .5;
        z-index: 0;
    }

    &__header,
    &__contact,
    &__disclaimer-links {
        position: relative;
        width: 100%;

        p {
            display: block;
            position: relative;
            z-index: 1;
        }
    }

    &__header-botch {
        top: 0;
        right: 0;
    }

    &__contact-botch {
        bottom: -1.6rem;
        left: 40%;
    }

    &__disclaimer-links-botch.botch {
        bottom: -3.2rem;
        right: -3.2rem;
    }
}

@include desktop {
    .c-impressum-page {
        padding: $spacing-32;
        margin-top: 0;
    }
}