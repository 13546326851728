@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-about-me-header {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &__image {
        width: 78rem;
        //height: 30rem;
        max-width: 78rem;
        .image img, .image {
            width: 78rem;
            max-width: 78rem;
        }
    }

    h2 {
        position: absolute;
        top: 56%;
        left: 2.5%;
        font-family: $font-family-licorice;
        color: $white;
        font-size: $font-size-48;
    }

    h3 {
        position: absolute;
        top: 80%;
        left: 2.5%;
        font-family: $font-family-amatic;
        color: $white;
        font-size: $font-size-24;
    }

    p {
        position: absolute;
        top: 74%;
        left: 2.5%;
        color: $white;
        font-size: 1.4rem;
    }
}

@media (min-width: 640px) {
    .c-about-me-header {
        &__image {
            width: 99.2rem;
            //height: 30rem;
            max-width: 99.2rem;
            .image img, .image {
                width: 99.2rem;
                max-width: 99.2rem;
            }
        }
    
        p {
            position: absolute;
            font-size: 1.8rem;
        }
    }
}

@include desktop {
    .c-about-me-header {
        &__image {
            width: 128rem;
            //height: 30rem;
            max-width: 128rem;
            .image img, .image {
                width: 128rem;
                max-width: 128rem;
            }
        }

        h2 {
            font-size: $font-size-64;
        }

        h3 {
            font-size: $font-size-32;
        }

        p {
            font-size: $font-size-24;
        }
    }
}