@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-contact {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-16;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $spacing-32;
    }

    &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-8;
    }

    &__illustration-container {
        display: none;
        visibility: hidden;
    }
    
    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 0;
        bottom: 0;
        opacity: .5;
        z-index: 0;
    }

    &__channels {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3.2rem;
    }

    &__channel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $spacing-8;
    }

    &__channel-image {
        display: none;
        // margin-top: $spacing-8;
        width: 12.8rem;
        height: 12.8rem;
    }

    &__channel-link {
        display: none;
    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $spacing-8;
    }
}

@media (min-width: 540px) {
    .c-contact {
        &__channels {
            gap: 12.8rem;
        }
        &__channel-image {
            display: block;
            width: 12.8rem;
            height: 12.8rem;
        }
        &__channel-link {
            display: initial;
        }
        &__channel-subtitle {
            display: none;
        }
        &__channel-button {
            display: none;
        }
    }
}

@media (min-width: 850px) {
    .c-contact {
        padding: $spacing-32;
        margin-bottom: $spacing-16;

        &__wrapper {
            flex-direction: row;
            align-items: flex-start;
            gap: $spacing-32;
            width: 100%;
        }

        &__content {
            flex: 1 1;
        }

        &__illustration-container {
            flex: 1 1;
            display: flex;
            visibility: visible;
            align-items: center;
            justify-content: center;
        }

        &__illustration {
            display: block;
            width: 30rem;
            height: 30rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }


    }
}