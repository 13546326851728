@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-agb {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    section {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;

        ol {
            list-style-type: none;
            counter-reset: bracketedlist;
            padding-left: $spacing-24;
            display: flex;
            flex-direction: column;
            gap: $spacing-8;

            li {
                position: relative;
                counter-increment: bracketedlist;
                max-width: 96rem;
                line-height: 1.6;

                &::before {
                    position: absolute;
                    content: "(" counter(bracketedlist) ") ";
                    top: 0rem;
                    left: -2.4rem;
                }
            }

            ul {
                list-style-type: disc;
                li::before {
                    content: none;
                }
            }
        }

        p {
            position: relative;
            max-width: 96rem;
            line-height: 1.6;
        }

    }


}

@media (min-width: 540px) {
    .c-agb {
        section {
            ol {
                padding-left: $spacing-32;
            }
    
        }
    }
}

@include desktop {
    .c-agb {
        padding: $spacing-32;
        margin-top: 0;
    }
}