@import "../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-massages-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $spacing-32;
    scroll-behavior: smooth;

    header {
        width: 100%;
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-32;
        padding: 0;
        padding-bottom: 6.4rem;
        width: 100%;
    }
}