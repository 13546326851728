@import "../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

html, body, #root {
    overflow: hidden;
}

body {
    width: 100dvw;
    height: 100dvh;
    font-display: swap;
}

#root {
    width: 100%;
    height: 100%;
}

.c-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    line-height: 1.6;

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }

    &__main-wrapper {
        flex-grow: 1;
    }
}


ul {
    list-style-position: outside;
    //padding-left: 22px;
    margin-left: $spacing-8;
    li {
        line-height: 1.6;
    }
}
