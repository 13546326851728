@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-menu {
    width: 100%;
    height: 7.2rem;
    padding: $spacing-4 $spacing-8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $shadow-downward;
    z-index: 1000;

    &__logo-name-container {
        display: flex;
        align-items: center;
        gap: $spacing-8;
        cursor: pointer;
    }

    &__name {
        font-family: "Licorice", cursive;
        font-weight: 400;
        color: $primary-color;
        font-size: $font-size-32;
        line-height: $heading-line-height;
    }

    &__logo {
        width: 6.4rem;
        height: 6.4rem;
    }

    &__items {
        position: fixed;
        top: 7.3rem;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        z-index: 1000;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity $transition-fast ease-in-out, visibility $transition-fast ease-in-out;

        &-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $spacing-16;
            top: 0;
            bottom: 9rem;
            left: 0;
            right: 0;
        }
    }

    .botch {
        user-select: none;
        pointer-events: none;
        position: absolute;
        z-index: -1;

        &.c-menu__botch-1 {
            width: 12rem;
            height: 12rem;
            top: -4rem;
            left: -2.6rem;
        }

        &.c-menu__botch-2 {
            width: 12.8rem;
            height: 12.8rem;
            top: 50%;
            right: -4.2rem;
            transform: translateY(-30%);
        }

        &.c-menu__botch-3 {
            width: 12rem;
            height: 12rem;
            bottom: -1.6rem;
            left: -1.6rem;
        }
    }

    &__calendly-button {
        position: absolute;
        bottom: 4.8rem;
        left: auto;
        right: auto;
    }

    &--mobile-menu-visible .c-menu__items {
        opacity: 1;
        visibility: visible;
    }

}


.c-menu-desktop-only {
    display: none !important;
    visibility: hidden !important;
}
  
@media (min-width: #{$breakpoint-lg}) {
    .c-menu-desktop-only {
        display: initial !important;
        visibility: inherit !important;
    }

    .c-menu-mobile-only {
        display: none !important;
        visibility: hidden !important;
    }
}

@media (min-width: #{$breakpoint-lg}) {
    .c-menu {
        padding: .6rem $spacing-16;
        height: 7.6rem;
        &__items {
            flex: 1 1;
            inset: auto;
            left: 50%;
            transform: translateX(-50%);
            z-index: auto;
            height: 20px;
            opacity: 1;
            visibility: visible;

            &-container {
                position: relative;
                inset: auto;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 0;
            }
        }

        &__calendly-button {
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
        }
    }
}