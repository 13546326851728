@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-opening-hours {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__text-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        z-index: 1;
    }

    &__hint {
        position: relative;
        z-index: 1;
    }

    &__day {
        display: block;
        width: 9rem;
        font-size: $font-size-24;
        color: $secondary-color;
        font-family: $font-family-amatic;
    }

    &__row {
        display: flex;
        align-items: baseline;
        gap: $spacing-8;
    }

    &__hours {
        display: block;
        
    }

    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 0;
        bottom: $spacing-32;
        opacity: .5;
        z-index: 0;
    }
}

@media (min-width: 780px) {
    .c-opening-hours {

        &__day {
            width: 9rem;
            font-size: $font-size-24;
        }

        &__text-container,
        &__hint {
            font-size: $font-size-16;
        }

        &__hint {
            margin-top: $spacing-8;
        }

        &__botch {
            position: absolute;
            width: 12.8rem;
            height: 12.8rem;
            right: 0;
            bottom: 0;
            opacity: .5;
            z-index: 0;
        }

    }
}

@include desktop {
    .c-opening-hours {
        gap: 1.6rem;
        margin-top: 0;
        padding: $spacing-32;
        margin-bottom: $spacing-16;

        &__day {
            width: 12rem;
            font-size: 2.8rem;
            font-weight: $font-weight-bold;
        }

        &__text-container,
        &__hint {
            font-size: $font-size-20;
        }

        &__hint {
            margin-top: $spacing-16;
        }

    }
    
}