@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-parental-leave {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing-16;
    margin-top: -$spacing-32;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__ticker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-16;

    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__date {
        color: $secondary-color;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-32;
        line-height: 1.6;

        &--update {
            z-index: 1;
            background-color: $grey-95;

            background-color: rgba($brand-yellow, 0.2);
            margin-left: -$spacing-16;
            margin-right: -$spacing-16;
            padding: $spacing-32;
            .c-parental-leave__ticker {

                h3 {
                    color: $primary-color;
                }
                box-shadow: $shadow-centered;
                //border: 1px solid $black;
                border-radius: 8px;
                padding: $spacing-32;
                background-color: $white;
            }
        }
    }

    &__title {
        position: relative;
        z-index: 1;
    }
    &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        p {
            position: relative;
            display: inline;
            z-index: 1;
        }
    }

    &__paragraph--highlight {
        color: $secondary-color;
    }

    &__highlight {
        color: $primary-color;
    }

    &__illustration-container {
        margin-top: $spacing-16;
        // display: none;
        // visibility: hidden;
    }

    .separator {
        margin-top: $spacing-16;
    }
    
    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 2rem;
        bottom: -1.6rem;
        opacity: .5;
        z-index: 0;
    }
}


@media (min-width: 850px) {
    .c-parental-leave {

        &__wrapper {
            flex-direction: row;
            align-items: center;
            gap: $spacing-32;
            width: 100%;
        }

        &__content {
            flex: 1 1;
        }

        &__illustration-container {
            flex: 1 1;
            display: flex;
            visibility: visible;
            align-items: center;
            justify-content: center;
        }

        &__illustration {
            display: block;
            width: 25.6rem;
            height: 25.6rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }
        .separator {
            display: none;
        }

        &__botch {
            bottom: -3.2rem;
        }
    }
}

@include desktop {
    .c-parental-leave {
        margin-top: 0;
        padding: $spacing-32;
        margin-bottom: $spacing-16;
        &__text {
            font-size: $font-size-20;
            position: relative;
            gap: $spacing-16;
        }

        &__content {
            &--update {
                margin-left: -$spacing-32;
                margin-right: -$spacing-32;
                padding: $spacing-32;
            }
        }
    }
}