@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-qualifications {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-16;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 1;
        gap: $spacing-32;
        width: 100%;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $spacing-32;

        p {
            position:relative;
            z-index: 1;
        }
    }

    &__illustration-container {
        
        display: flex;
        visibility: visible;
        align-items: center;
        justify-content: center;
    }

    &__image {
        flex: 1 1;
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        .image img, .image {
            max-width: 100%;
        }
    }

    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 0;
        bottom: 0;
        opacity: .5;
        z-index: 0;
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-8;
        z-index: 1;

        ul {
            list-style-position: outside;
            //padding-left: 22px;
            margin-left: $spacing-8;
        }

        li {
            font-size: $font-size-16;
            line-height: 2.8rem;
        }
    }
}

@media (min-width: 640px) {
    .c-qualifications {
        &__wrapper {
            flex-direction: row;
            align-items: flex-start;
            gap: $spacing-32;
            width: 100%;
        }

        &__image {
            display: block;
            width: 32rem;
            height: 41.27rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }

        &__content {
            flex: 1 1;
        }
        
    }
}

@media (min-width: 840px) {
    .c-qualifications {
        &__image {
            display: block;
            width: 40rem;
            height: 51.59rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }
        
        &__list {
            li {
                // font-size: $font-size-20;
                line-height: 3rem;
            }
        }
    }
}

@include desktop {
    .c-qualifications {
        margin-top: $spacing-16;
        padding: $spacing-32;
        margin-bottom: $spacing-16;

        &__image {
            display: block;
            width: 60rem;
            height: 46.5rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }




    }
}