@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-menu-button {
    height: 4.8rem;
    width: 5.6rem;
    background-color: transparent;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:focus {
        outline-color: $secondary-color;
    }

    &__container {
        width: 2.4rem;
        display: flex;
        flex-direction: column;
        gap: $spacing-4;
    }

    &__bar {
        background-color: $black;
        height: 0.2rem;
        width: 100%;
        border-radius: .4rem;
        transition: all $transition-fast ease-in-out;
    }

    &__bar--top {
        transform-origin: 0 0;
    }
    &__bar--bottom {
        transform-origin: 0 100%;
    }
}

.c-menu-button {
  &--open {
    .c-menu-button__bar {
        &--top {
            transform: translate(0.4rem, -0.2rem) rotate(45deg);
        }
        &--middle {
            opacity: 0;
        }
        &--bottom {
            transform: translate(0.4rem, 0.2rem) rotate(-45deg);
        }
    }
  }
}