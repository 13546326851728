@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-massage-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    justify-content: flex-start;
    width: 100%;
    padding: $spacing-16;
    overflow: hidden;

    &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1;
    }

    &__content {
        display: flex;
        gap: $spacing-16;
        flex-direction: column;
    }

    &__teaser {
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        align-items: center;
        

        .c-calendly-button {
            display: none;
        }

        .illustration {
            margin-top: -$spacing-32;
            margin-bottom: -$spacing-32;
            z-index: 0;
        }
    }

    &__teaser-content {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
        padding-left: $spacing-32;
        padding-right: $spacing-32;
    }

    &__price,
    &__price-mobile {
        display: none;
        flex-direction: column;
        gap: $spacing-4;

        p {
            display: flex;
            flex-direction: column;
        }
    }



    &__description {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: $spacing-32;
    }

    &__description-container,
    &__effect-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        z-index: 1;
    }

    &__description-text {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;
    }

    &__price-mobile {
        display: flex;
        z-index: 1;
    }

    &__calendly-button-mobile {
        margin-top: $spacing-16;
        display: flex;
        z-index: 1;
        align-self: center;
    }


    &__illustration {
        width: 25.6rem;
    }

    & .botch {
        position: absolute;
        width: 25.6rem;
        height: 25.6rem;
        opacity: .5;
        z-index: 0;
        right: -3.2rem;
        bottom: -3.2rem;
    }
}

@media (min-width: 640px) {
    .c-massage-section {

        &__header {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
        &__content {
            flex-direction: row;
            gap: $spacing-16;
        }

        &__teaser {
            align-items: flex-start;
            gap: $spacing-8;

            .c-calendly-button {
                display: flex;
            }


            .illustration {
                margin-top: 0;
                margin-bottom: 0;
                z-index: initial;
            }
        }

        &__price {
            display: flex;
        }

        &__price-mobile,
        &__calendly-button-mobile {
            display: none;
        }

    }
}

@media (min-width: 992px) {
    .c-massage-section {
        padding: $spacing-32;
    }
}

@include desktop {
    .c-massage-section {

    }
}
