@import "../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-gallery {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
    padding: 0 0;
    margin-top: 0;
    margin-bottom: $spacing-32;


    .image__container,
    .image__wrapper img,
    .image img {
        width: 100% !important;
        max-width: 64rem;
    }

}

@media (min-width: 640px) {
    .c-gallery {
        margin-top: $spacing-16;
        margin-bottom: 0;
        padding: 0;
        flex-direction: row;
        gap: $spacing-16;

        .image__container {
            flex: 1 1;
        }
    }
}

@include desktop {
    .c-gallery {
        margin: 0;
        padding: 0;
    }
}