@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-home {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44.1rem;
    overflow: hidden;
    gap: $spacing-16;

    &__title {
        font-family: $font-family-amatic;
        font-weight: 400;
        font-size: $font-size-64;
        line-height: $heading-line-height;
        color: $primary-color;
        text-align: center;
    }

    &__subtitle {
        font-family: $font-family-licorice;
        font-weight: 400;
        font-size: 4.6rem;
        line-height: $heading-line-height;
        color: $secondary-color;
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $spacing-8;
    }

    &__illustration {
        width: 12.8rem;
        height: 12.8rem;
    }

    &__quote {
        text-align: center;
        line-height: 1.6;
    }

    &__flower-left,
    &__flower-right {
        width: 12.8rem;
        position: absolute;
        z-index: 0;
        opacity: .4;
        top: 14.6rem;
    }

    &__flower-left {
        left: 0;
    }

    &__flower-right {
        right: 0;
        transform: rotate(180deg);
    }
}

@media (min-width: 540px) and (max-width: #{$breakpoint-desktop}) {
    .c-home {
        height: 50rem;

        &__title {
            font-size: 8.4rem;
        }
    
        &__subtitle {
            font-size: 5.8rem;
        }
    
        &__content {
            gap: 0;
        }
    
        &__illustration {
            width: 16rem;
            height: 16rem;
        }
    
        &__quote {
            font-size: $font-size-20;
        }
    
        &__flower-left,
        &__flower-right {
            width: 20rem;
            top: 12rem;
        }
    }
}

@include desktop {
    .c-home {
        height: 64rem;

        &__title {
            font-size: 12.8rem;
        }
    
        &__subtitle {
            margin-top: -1rem;
            font-size: 8.6rem;
        }
    
        &__content {
            gap: 0;
        }
    
        &__illustration {
            width: 19.2rem;
            height: 19.2rem;
        }
    
        &__quote {
            font-size: $font-size-24;
        }
    
        &__flower-left,
        &__flower-right {
            width: 28rem;
            top: 2.8rem;
        }
    }
}