@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-introduction {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-16;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        gap: $spacing-32;
    }

    &__title {
        margin-top: $spacing-16;
        font-size: 2.8rem;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $spacing-16;

        p {
            position:relative;
            z-index: 1;
        }
    }

    &__illustration-container {
        flex: 1 1;
        display: flex;
        visibility: visible;
        align-items: center;
        justify-content: center;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        .image img, .image {
            max-width: 100%;
        }
    }

    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 0;
        bottom: 0;
        opacity: .5;
        z-index: 0;
    }

    &__botch-1 {
        right: 20%;
        top: 3.4rem;
        opacity: .3;
    }

    &__botch-2 {
        left: 0;
        top: 48%;
    }

    &__botch-3 {
        right: 4.6rem;
        bottom: 0;
    }
}

@media (min-width: 640px) {
    .c-introduction {
        &__wrapper {
            flex-direction: row;
            align-items: flex-start;
            gap: $spacing-32;
            width: 100%;
        }

        &__title {
            margin-top: 0;
            font-size: $font-size-48;
        }

        &__image {
            display: block;
            width: 32rem;
            height: 41.27rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }

        &__content {
            flex: 1 1;
        }
        
    }
}

@media (min-width: 840px) {
    .c-introduction {
        &__image {
            display: block;
            width: 40rem;
            height: 51.59rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }
        
    }
}

@include desktop {
    .c-introduction {
        padding: $spacing-32;
        margin-bottom: $spacing-16;

        &__image {
            display: block;
            width: 45.2rem;
            height: 58.3rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }

        &__title {
            font-size: $font-size-64;
        }




    }
}