@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-menu-item {
    background-color: transparent;
    border-style: none;
    padding: 1.6rem;
    color: $black;
    text-underline-offset: 4px;
    cursor: pointer;
    transition: all $transition-fast ease-in-out;

    &:hover {
        text-decoration: underline;
    }
    
    &--active {
        text-decoration: underline;
        color: $secondary-color;
    }
}


