@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-location {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    &__map,
    &__driveway {
        width: 100%;
        max-width: 100%;

        .image img,
        img.svg-image {
            max-width: 100%;
            box-shadow: $shadow-centered;
        }
    }

    &__map-and-driveway-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-32;
    }

    figure {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
    }

    figcaption {
        width: 100%;
    }

    &__address-container {
        display: flex;
        gap: $spacing-8;
        flex-direction: column;
        align-items: center;
        background-color: $grey-95;
        margin-left: -$spacing-16;
        margin-right: -$spacing-16;
        padding: $spacing-32 0;
    }

    &__address {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__address-button {
        margin-top: $spacing-8;
    }

    &__benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__benefits-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__benefit {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__benefits-title {
        z-index: 1;
        margin-bottom: -$spacing-8;
    }

    &__benefit-illustration {
        position: relative;
        width: 19.2rem;
        height: 19.2rem;
        margin-bottom: -$spacing-16;
        z-index: 0;
    }

    &__benefit-description {
        position: relative;
        text-align: center;
        z-index: 1;
    }
}

@media (min-width: 800px) {
    .c-location {
        &__map-and-driveway-container {
            flex-direction: row;

            figure {
                flex: 1 1;

                &.c-location__map-container {
                    flex-grow: 428;
                }
                &.c-location__driveway-container {
                    flex-grow: 572;
                }
            }
        }

        &__benefits-list {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: $spacing-32;
            row-gap: 0;
        }

        &__benefit {
            width: calc(50% - $spacing-16);
        }
    }
}

@include desktop {
    .c-location {
        display: flex;
        flex-direction: column;
        padding: $spacing-32;
        margin-top: 0;
        gap: $spacing-32;
        margin-bottom: $spacing-32;

        &__address-container {
            margin-left: -$spacing-32;
            margin-right: -$spacing-32;
        }

        &__benefits-list {
            flex-direction: row;
            flex-wrap: nowrap;
            gap: $spacing-32;
        }

        &__benefits-title {
            z-index: 1;
            margin-bottom: -$spacing-32;
        }

        &__benefit {
            flex-grow: initial;
        }
    }
}