@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-privacy-policy {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    section {
        display: flex;
        flex-direction: column;
        gap: $spacing-24;

        & > div {
            display: flex;
            flex-direction: column;
            gap: $spacing-12;

            h4 {
                font-size: $font-size-24;
            }

            & > div {
                h5 {
                    font-weight: bold;
                    margin-bottom: $spacing-2;
                }
            }
        }

    }


}

@media (min-width: 540px) {
    .c-privacy-policy {
        section {
            ol {
                padding-left: $spacing-32;
            }
    
        }
    }
}

@include desktop {
    .c-privacy-policy {
        padding: $spacing-32;
        margin-top: 0;
    }
}