@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-room {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: $spacing-16;
    margin-top: $spacing-16;
    gap: $spacing-32;
    margin-bottom: $spacing-32;

    &__image-container {
        display: flex;
        gap: $spacing-16;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        .image__container,
        .image__wrapper img,
        .image img {
            width: 100% !important;
            max-width: 64rem;
        }
    }

}

@media (min-width: 640px) {
    .c-room {
        &__image-container {
            flex-direction: row;
    
            .image__container {
                flex: 1 1;
            }
        }
    }
}

@include desktop {
    .c-room {
        padding: $spacing-32;
        margin-top: 0;
        gap: $spacing-32;
        margin-bottom: $spacing-32;
    }
}