@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-massages {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-16;
    margin-bottom: $spacing-32;

    &__title {
        margin-bottom: $spacing-8;
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    &__card-container {
        position: relative;
        padding: $spacing-24 0;
    }

    &__card {
        position: relative;
        z-index: 1;
    }

    &__card-background {
        position: absolute;
        bottom: 0;
        top: 15.2rem;
        left: 0;
        right: 0;
        margin-left: -$spacing-16;
        margin-right: -$spacing-16;
        background-color: $grey-95;
        z-index: 0;
    }

    &__calendly-button {
        margin-top: $spacing-24;
        align-self: center;
    }

}

@media (min-width: 540px) {
    .c-massages {
    }
}

@media (min-width: 700px) {
    .c-massages {
    }
}


@include desktop {
    .c-massages {
        gap: $spacing-32;
        margin-top: 0;
        padding: $spacing-32;
        padding-bottom: 10.6rem;
        margin-bottom: $spacing-32;

        &__container {
            width: 100%;
            flex-direction: row;
            gap: $spacing-24;
            justify-content: center;
            align-items: stretch;
        }

        &__card {
            height: 100%;
        }

        &__card-container {
            padding: 0;
        }

        &__card-background.c-desktop-only {
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-left: -$spacing-32;
            margin-right: -$spacing-32;
            margin-bottom: -10.6rem;
        }

        &__calendly-button {
            position: absolute;
            bottom: $spacing-32;
            margin-top: 0;
        }
    }
}