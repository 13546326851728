@import "../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design";

.c-promotion {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacing-16;
    margin-top: -$spacing-32;
    gap: $spacing-16;
    margin-bottom: $spacing-32;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-16;
        line-height: 1.6;
    }

    &__title {
        position: relative;
        z-index: 1;
    }
    &__text {
        position: relative;
        p {
            position: relative;
            display: inline;
            z-index: 1;
        }
    }

    &__paragraph--highlight {
        color: $secondary-color;
    }

    &__highlight {
        color: $primary-color;
    }

    &__illustration-container {
        margin-top: $spacing-16;
        // display: none;
        // visibility: hidden;
    }
    
    &__botch {
        position: absolute;
        width: 12.8rem;
        height: 12.8rem;
        right: 2rem;
        bottom: -1.6rem;
        opacity: .5;
        z-index: 0;
    }
}


@media (min-width: 850px) {
    .c-promotion {

        &__wrapper {
            flex-direction: row;
            align-items: center;
            gap: $spacing-32;
            width: 100%;
        }

        &__content {
            flex: 1 1;
        }

        &__illustration-container {
            flex: 1 1;
            display: flex;
            visibility: visible;
            align-items: center;
            justify-content: center;
        }

        &__illustration {
            display: block;
            width: 25.6rem;
            height: 25.6rem;
            max-width: 100%;
            img {
                max-width: 100%;
            }
        }

        &__botch {
            bottom: -3.2rem;
        }
    }
}

@include desktop {
    .c-promotion {
        margin-top: 0;
        padding: $spacing-32;
        margin-bottom: $spacing-16;
        &__text {
            font-size: $font-size-20;
        }

        &__text {
            position: relative;
            gap: $spacing-16;
        }
    }
}